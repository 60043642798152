import React, { useState, useEffect, useContext, useRef } from "react";
import "./Header.css";
import { IoMenu } from "react-icons/io5";
import { NavLink } from "react-router-dom";
import { ThemeContext } from "../ThemeContext";
import { IoCopyOutline } from "react-icons/io5";
import Mycontextprovider from "../ContextProvider";
import { MdClose } from "react-icons/md";
import { IoCheckmarkDoneCircle } from "react-icons/io5";

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [copied, setCopied] = useState(true);

  const { toggleTheme, isDarkMode } = useContext(ThemeContext);
  const { Isdisconnected_seted, Isdisconnected } =
    useContext(Mycontextprovider);
  const [kaanch_address, setkaanch_address] = useState();
  const [kaanchobjnotfound, setkaanchobjnotfound] = useState(false);
  // const [menuOpen, setmenuOpen] = useState(false);
  

  const menuRef = useRef();

  const handleThemeToggle = () => {
    toggleTheme();
  };

  // routing functions to go to other page
  useEffect(() => {
    const timer = setTimeout(() => {
      if (window.kaanch) {
        setkaanchobjnotfound(false);
        window.kaanch.isConnected().then((connected) => {
          if (connected) {
            window.kaanch.account().then((account) => {
              setkaanch_address(account);
              Isdisconnected_seted(true);
            });
          }
        });
      } else {
        console.error("kaanch object not found on window");
        setkaanchobjnotfound(true);
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [Isdisconnected]);

  async function connecting() {
    try {
      if (window.kaanch) {
        if (window.kaanch.connect) {
          const Connect = await window.kaanch.connect();
          // console.log("Connect", Connect);
          setkaanch_address(Connect);
          Isdisconnected_seted(true);
        }
      } else {
        console.error("kaanch object not found on window");
      }
    } catch (error) {
      console.error("Error connecting:", error);
    }
  }

  async function disconnecting() {
    try {
      await window.kaanch.disconnect();
      setkaanch_address(null);
      Isdisconnected_seted(false);
      localStorage.removeItem("referralAddress");
    } catch (error) {
      console.error("Error connecting:", error);
    }
  }

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 832);
    };

    handleResize(); // Initial check

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(!showMenu);
      }
    };

    if (showMenu && isMobile) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };
  const close=()=>{
setShowMenu(false)
  }

  const handleCopyClick = () => {
    const textToCopy = kaanch_address;

    // Create a temporary textarea element to hold the text
    const textarea = document.createElement("textarea");
    textarea.value = textToCopy;
    document.body.appendChild(textarea);

    // Select the text within the textarea
    textarea.select();
    document.execCommand("copy");

    // Remove the temporary textarea
    document.body.removeChild(textarea);

    // Show "Tick" icon and hide "Copy" icon
    setCopied(false);

    // Set a timeout to reset the icon back to "Copy" after a few seconds
    setTimeout(() => {
      setCopied(true);
    }, 1000); // 2000 milliseconds = 2 seconds
  };
  return (
    <nav
      className={`navbar ${
        isDarkMode ? "dark-mode-header-page" : "light-mode-header-page"
      }  ${showMenu && isMobile ? "show-menu" : ""}`}
    >
      <div className="navbar__left">
        <div className="navbar__logo">
          {isDarkMode ? (
            <img
              className="navimg23"
              src={`${process.env.PUBLIC_URL}/Image/logo_light.png`}
              alt="kaanch"
            />
          ) : (
            <img
              className="navimg23"
              src={`${process.env.PUBLIC_URL}/Image/logo_black.png`}
              alt="kaanch"
            />
          )}
        </div>
        <div
          ref={menuRef}
          className={`navbar__menu ${showMenu && isMobile ? "show" : ""}`}
        >
          {/* Menu items */}
          <NavLink to="/" activeClassName="active">
            Dashboard
          </NavLink>
          <NavLink to="/market" activeClassName="active">
            Market
          </NavLink>
          <NavLink to="/stake" activeClassName="active">
            Stake
          </NavLink>
          <NavLink to="/gov" activeClassName="active">
            Buy
          </NavLink>
        </div>
      </div>
      <div className="navbar__right">
        <div className="navbar__button-dark-white-toggle">
          {isDarkMode ? (
            <img
              className="darkWhite "
              onClick={handleThemeToggle}
              src={`${process.env.PUBLIC_URL}/Image/light-switch.png`}
              alt="kaanch"
            />
          ) : (
            <img
              className="darkWhite"
              onClick={handleThemeToggle}
              src={`${process.env.PUBLIC_URL}/Image/dark-switch.png`}
              alt="kaanch"
            />
          )}
        </div>
        <div className="navbar__button">
          {Isdisconnected ? (
            <div className="" style={{ display: "flex", gap: "10px" }}>
              <div className="">
                {kaanch_address.slice(0, 4)}..{kaanch_address.slice(-4)}
                {"  "}
                <span className="darkWhite" style={{ cursor: "pointer" }}>
                <span
                    className="material-symbols-outlined header-copy-icon"
                    onClick={handleCopyClick}
                  >
                    {copied ? "content_copy" : "done"}
                  </span>
                </span>
              </div>

              <div>
                {" "}
                <img
                  className="darkWhite"
                  onClick={disconnecting}
                  src={`${process.env.PUBLIC_URL}/Image/disconnectRed.png`}
                  alt="kaanch"
                />
              </div>
            </div>
          ) : (
            <div
              className=""
              style={{ cursor: "pointer", fontWeight: "bold" }}
              onClick={connecting}
            >
              Connect
            </div>
          )}
        </div>
        {/* Toggle button for mobile */}
        {!showMenu?
          <div className="navbar__toggle" onClick={toggleMenu}>
          <IoMenu />
      
        </div>:<div className="navbar__toggle-close" onClick={close}><MdClose /></div>
          }
      </div>
    </nav>
  );
};

export default Header;

