import "./Governance.css";
import "./Stake.css";

import React, { useContext, useEffect, useState } from "react";
import { RiArrowDownSLine } from "react-icons/ri";
import { MdOutlineSwapVerticalCircle } from "react-icons/md";
import { ThemeContext } from "../ThemeContext";
const Governance = () => {
  const [img,setimg]=useState("https://seeklogo.com/images/T/tether-usdt-logo-FA55C7F397-seeklogo.com.png")
  const [name,setname]=useState('USDT')
  const { isDarkMode } = useContext(ThemeContext);
  const [buyKanch, setbuykanch] = useState(0);
  const swap = () => {
    setbuykanch((prev) => !prev);
  };

  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleMenuItemClick=(item)=>{
    if(item=='USDT'){
      setimg("https://seeklogo.com/images/T/tether-usdt-logo-FA55C7F397-seeklogo.com.png")
      setname("USDT")

    }
    else if(item==='USDC'){
      setimg("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS8rEIs8nc1hf59AQZ8hpzbIgi7CVxnRuGPr_RE2wSK6g&s")
      setname("USDC")
    }
    else if(item==='DAI'){
      setimg("https://cryptologos.cc/logos/multi-collateral-dai-dai-logo.png")
      setname("DAI")
    }
   
   
     
  }

  return (
    <div className={isDarkMode ? "dark-mode-homepage" : "light-mode-homepage"}>
      <div className="home">
        <div className="headerTopBg"></div>
        <div className="stake-container">
          <div className="firstDivHeading">
            <div className="" style={{ fontSize: "20px" }}>
              {buyKanch ? <>Sell USDe</> : <>Buy USDe</>}
            </div>
          </div>
          <div className="secDivStake">
            <div className="secDivStakeFirstColumn">You Send</div>
            <div className="secDivStakeSecColumn">
              <input placeholder="0" className="inputsecColStake"></input>
              {buyKanch ? (
                <>
                  <div className="secDivStakeSecColumn-selectdrop-gov">
                    <img
                      className="imgLogo"
                      alt=""
                      src={`${process.env.PUBLIC_URL}  /Image/kaanch1.png`}
                    />
                    Kaanch <span>{/* <RiArrowDownSLine /> */}</span>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="secDivStakeSecColumn-selectdrop-gov"
                    style={{ cursor: "pointer" }}
                    onClick={toggleDropdown}
                  >
                    <img
                      className="imgLogo"
                      src={img}
                    />
                   <span style={{marginLeft:'4px'}}>{name}</span>
                    <span>
                      <RiArrowDownSLine />
                    </span>
                  
                    {showDropdown && (
                    <div className="dropdown-menu">
                      <div className="dropdown-item"  onClick={() => handleMenuItemClick('USDC')}>
                        <div><img  className="imgLogo" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS8rEIs8nc1hf59AQZ8hpzbIgi7CVxnRuGPr_RE2wSK6g&s"/></div>
                        <div>USDC</div>
                      </div>
                      <div className="dropdown-item"  onClick={() => handleMenuItemClick('USDT')}>
                      <div><img  className="imgLogo" src="https://seeklogo.com/images/T/tether-usdt-logo-FA55C7F397-seeklogo.com.png"/></div>
                       <div>USDT</div>
                        </div>
                      <div className="dropdown-item"  onClick={() => handleMenuItemClick('DAI')}>

                      <div><img  className="imgLogo" src="https://cryptologos.cc/logos/multi-collateral-dai-dai-logo.png"/></div>
                        <div>DAI</div>
                      </div>
                    
                    </div>
                  )}
                  </div>
                 
                </>
              )}
            </div>
            <div className="secDivStakeSecColumn-max-balance">
              <button className="max-btn">Max</button> Balance: 0
            </div>
          </div>
          <div className="swapp-btn-section">
            {/* <hr className="swapp-btn-left" />
             */}
            <span></span>
            <span className="swapp-btn" onClick={swap}>
              <MdOutlineSwapVerticalCircle />
            </span>
            {/* <hr className="swapp-btn-right" /> */}
            <span></span>
          </div>
          <div className="secDivStake">
            <div className="secDivStakeFirstColumn">You Recieve</div>
            <div className="secDivStakeSecColumn">
              <input placeholder="0" className="inputsecColStake"></input>
              {!buyKanch ? (
                <>
                  <div className="secDivStakeSecColumn-selectdrop-gov">
                    <img
                      className="imgLogo"
                      alt=""
                      src={`${process.env.PUBLIC_URL}  /Image/kaanch1.png`}
                    />
                    Kaanch <span>{/* <RiArrowDownSLine /> */}</span>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="secDivStakeSecColumn-selectdrop-gov"
                    style={{ cursor: "pointer" }}
                    onClick={toggleDropdown}
                  >
                  
                   <img
                      className="imgLogo"
                      src={img}
                    />
                 
                    <span style={{marginLeft:'4px'}}>{name}</span>
                    <span>
                      <RiArrowDownSLine />
                    </span>
                    {showDropdown && (
                    <div className="dropdown-menu">
                      <div className="dropdown-item"  onClick={() => handleMenuItemClick('USDC')}>
                        <div><img  className="imgLogo" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS8rEIs8nc1hf59AQZ8hpzbIgi7CVxnRuGPr_RE2wSK6g&s"/></div>
                        <div>USDC</div>
                      </div>
                      <div className="dropdown-item"  onClick={() => handleMenuItemClick('USDT')}>
                      <div><img  className="imgLogo" src="https://seeklogo.com/images/T/tether-usdt-logo-FA55C7F397-seeklogo.com.png"/></div>
                       <div>USDT</div>
                        </div>
                      <div className="dropdown-item"  onClick={() => handleMenuItemClick('DAI')}>

                      <div><img  className="imgLogo" src="https://cryptologos.cc/logos/multi-collateral-dai-dai-logo.png"/></div>
                        <div>DAI</div>
                      </div>
                    
                    </div>
                  )}
                  </div>
                 
                </>
              )}
            </div>
            <div className="secDivStakeSecColumn-max-balance"> Balance: 0</div>
          </div>
          <div className="fifthDiv">
            <div>Max Slipage : 0.11%</div>
            <div>Gas Price $4.56</div>
          </div>

          <div className="seventhDiv">{buyKanch ? <>Sell</> : <>Buy</>}</div>
        </div>
      </div>
    </div>
  );
};

export default Governance;
