import "./Market.css";
import React, { useContext } from "react";

// import Mycontextprovider from "../ContextProvider";
import { FaSearch } from "react-icons/fa";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { ThemeContext } from "../ThemeContext";
import { RiExpandUpDownLine } from "react-icons/ri";

const Market = () => {
  const { isDarkMode } = useContext(ThemeContext);
  const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
  return (
    <div className={isDarkMode ? "dark-mode-homepage" : "light-mode-homepage"}>
      <div className="dashboard">
        <div className="headerTopBg">
          <div className="headerTop-content1">
            <div className="headerTop-content-box1">
              <div>Total Market Size</div>
              <div className="headerTop-content-box-value1">$5.5B</div>
            </div>
            <div className="headerTop-content-box1">
              <div>Total Available</div>
              <div className="headerTop-content-box-value1">$3.2B</div>
            </div>
            <div className="headerTop-content-box1">
              <div>Total Borrow</div>
              <div className="headerTop-content-box-value1">$2.3B</div>
            </div>
          </div>
        </div>

        <div className="kaanchAssetBoxContainer">
          <div className="KaanchAssetSearchbtn">
            <div className="kaanchAsset">Kaanch asset</div>

            <div className="input-group">
              <span className="input-group-text search-icon-box search-symbol" >
                <span class="material-symbols-outlined search-icon">
                  search
                </span>
              </span>
              <input
                type="text"
                className="form-control"
                placeholder="Search Asset name, symbol, or address"
              />
            </div>
          </div>
          <div className="totalBorrowedApyBox">
            <div className="secparentBox">
              {" "}
              <div className="dashBoardBox">
                <div>Total Borrowed</div>
                <div>$15M</div>
              </div>
              <div className="dashBoardBox">
                <div>Borrowed Rate APY</div>
                <div>2.77% to 5%</div>
              </div>
              <div className="dashBoardBox">
                <div>Eligible for</div>
                <div>$1.5K</div>
              </div>
            </div>
            <div className="detailsecdivBox">View Details</div>
          </div>
          {/* <span className="contentbackground">
            <div className="contentHeading">
              <div>
                Asset
                <span className="arrowBtn">
                  {" "}
                  <RiExpandUpDownLine />
                </span>
              </div>
              <div>
                Total Supplied{" "}
                <span className="arrowBtn">
                  {" "}
                  <RiExpandUpDownLine />
                </span>
              </div>
              <div>
                Supply APY{" "}
                <span className="arrowBtn">
                  {" "}
                  <RiExpandUpDownLine />
                </span>
              </div>
              <div>
                Total Borrowed{" "}
                <span className="arrowBtn">
                  {" "}
                  <RiExpandUpDownLine />
                </span>
              </div>
              <div>
                Borrow APY, variable{" "}
                <span className="arrowBtn">
                  {" "}
                  <RiExpandUpDownLine />
                </span>
              </div>
              <div></div>
            </div>

            {arr.map((i) => (
              <div className="contentHeadingDetails">
                <div className="imgBoxDiv">
                  <div>
                    <img
                      className="imgBox"
                      style={{ width: "24px", height: "26px" }}
                      src="https://w7.pngwing.com/pngs/368/176/png-transparent-ethereum-cryptocurrency-blockchain-bitcoin-logo-bitcoin-angle-triangle-logo-thumbnail.png"
                    />
                  </div>
                  <div className="name">Ethreum</div>
                </div>
                <div>$1.5M</div>
                <div>0.01%</div>
                <div>3.2K</div>
                <div>0.28%</div>

                <div className="detailBox" style={{ cursor: "pointer" }}>
                  Details
                </div>
              </div>
            ))}
          </span> */}
          <div className="autoScroll">
            <div className="tableDiv">
              <table className="tableHeadingContent">
                <thead>
                  <tr className="headingRow">
                    <th>
                      Assets <RiExpandUpDownLine />
                    </th>
                    <th>
                      Total Supplied <RiExpandUpDownLine />
                    </th>
                    <th>
                      Supply APPY <RiExpandUpDownLine />
                    </th>
                    <th>
                      Total Borrowed <RiExpandUpDownLine />
                    </th>
                    <th>
                      Borrow APY,variable <RiExpandUpDownLine />
                    </th>
                  </tr>
                </thead>
                <tbody className="tbodyRow">
                  {arr.map((i) => (
                    <tr className="tableRowContent">
                      <td>
                        <img
                          className="img"
                          alt=""
                          src="https://w7.pngwing.com/pngs/368/176/png-transparent-ethereum-cryptocurrency-blockchain-bitcoin-logo-bitcoin-angle-triangle-logo-thumbnail.png"
                        ></img>{" "}
                        Ethreum
                      </td>
                      <td>$1.5M</td>
                      <td>0.01%</td>
                      <td>3.2K</td>
                      <td>0.28%</td>
                      <td className="detailTableBox">
                        <button>Details</button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Market;
