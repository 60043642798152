import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../ThemeContext";
// import Mycontextprovider from "../ContextProvider";
import "./DashboardStyle.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

import { RiExpandUpDownLine } from "react-icons/ri";
import { MdOutlineDone } from "react-icons/md";

const Dashboard = () => {
  const [showPopup, setshowPopup] = useState(false);
  const [showPopUpBorrow,setshowPopUpBorrow]=useState(false)
  // const [isPopup, setisPopup] = useState(false);
  const { isDarkMode } = useContext(ThemeContext);
  // const { isconnected } = useContext(Mycontextprovider);
  const [hidecontent, setHidecontent] = useState(true);
  const [hidecontenttwo, setHidecontenttwo] = useState(true);
  const [togleSupp, settogleSupp] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // const { Isdisconnected_seted, Isdisconnected, IshomeORreferral, homeORreferral, } = useContext(Mycontextprovider);
  const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

  const supply = () => {
    setshowPopup((prev) => !prev);
  };
  const borrow=()=>{
    setshowPopUpBorrow((prev)=>!prev)
  }
  const hideHandler = () => {
    setHidecontent((prev) => !prev);
  };
  const hideHandlertwo = () => {
    setHidecontenttwo((prev) => !prev);
  };

  const toggleSupplyBorrow = () => {
    settogleSupp((prev) => !prev);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const closePopup=(event)=>{
    if (event.target.classList.contains('popup-overlay')) {
      setshowPopup(false);
    }
  }

  const closePopupBorrow=(event)=>{
    if (event.target.classList.contains('popup-overlay')) {
      setshowPopUpBorrow(false);
    }
  }


 

  

  return (
    <div className={isDarkMode ? "dark-mode-homepage" : "light-mode-homepage"}>
      <div className="dashboard">
        <div className="headerTopBg">
          <div className="headerTop-content">
            <div className="headerTop-content-box">
              <div>Net Worth</div>
              <div className="headerTop-content-box-value">$0</div>
            </div>
            <div className="headerTop-content-box">
              <div>Net APY</div>
              <div className="headerTop-content-box-value">-</div>
            </div>
            <div className="headerTop-content-box">
              <div>Health Factor</div>
              <div className="headerTop-content-box-value">-</div>
            </div>
          </div>

          <div className="toggleChange">
            <div className="toggleChange-supply" onClick={toggleSupplyBorrow} >
              Supply
            </div>
            <div className="toggleChange-supply" onClick={toggleSupplyBorrow}  >
              Borrow
            </div>
          </div>
        </div>

        {/* For the popup */}
        {showPopup && (
          <div className="popup-overlay" onClick={closePopup}>
            <div className="popUpBox">
              <div className="popup-header">
                <span>Supply Kaanch</span>
                <span style={{ cursor: "pointer" }} onClick={supply}>
                  X
                </span>
              </div>
              <div className="" style={{ color: "white", marginTop: "14px" }}>
                Amount :
              </div>
              <div className="amount-box">
                {/* <div style={{marginTop:'7px'}}>amount</div> */}
                <input className="inputAmount" placeholder="0"></input>
                <div className="" style={{ display: "flex" }}>
                  <div className="totalimgmaxnameAmountBox">
                    <div>
                      <div
                        className="kanch-btn-box-inAmount"
                        style={{ marginBottom: "11px" }}
                      >
                        <div>
                          <img
                            className="amount-img"
                            style={{
                              height: "24px",
                              width: "24px",
                              marginTop: "3px",
                            }}
                            alt=""
                            src={`${process.env.PUBLIC_URL}  /Image/kaanch1.png`}
                          />
                        </div>
                        <div
                          style={{
                            marginRight: "15px",
                            marginTop: "7px",
                            fontSize: "12px",
                          }}
                        >
                          KNCH
                        </div>
                      </div>
                    </div>
                    <div className="max-btn-box">max</div>
                  </div>
                </div>
               
              </div>
              <div className="wallet-balance">Wallet balance:100000</div>
              <div style={{ color: "white", marginTop: "30px" }}>
                Transaction Details :
              </div>
              <div className="transanction-box">
                <div className="transanction-box-content1">
                  <div>Supply APY</div>
                  <div>
                    <span className="spanColorAppy">1.78%</span>
                  </div>
                </div>
                <div className="transanction-box-content1">
                  <div>Collateralization</div>
                  <div>
                    <span className="spanColorenable">Enable</span>
                  </div>
                </div>
                <div className="transanction-box-content1">
                  <div>Supply APY</div>
                  <div>
                    <span className="spanColorHealthFact">1.68</span>
                  </div>
                </div>
              </div>
              <div className="transfer-btn">Enter your Amount</div>
             
            </div>
          </div>
        )}
        {/* end of  the popup of supply */}


        {/* For the popup borrow*/}
        {showPopUpBorrow && (
          <div className="popup-overlay" onClick={closePopupBorrow}>
            <div className="popUpBox">
              <div className="popup-header">
                <span>Borrow Balance</span>
                <span style={{ cursor: "pointer" }} onClick={borrow}>
                  X
                </span>
              </div>
              <div className="" style={{ color: "white", marginTop: "14px" }}>
                Amount :
              </div>
              <div className="amount-box">
                {/* <div style={{marginTop:'7px'}}>amount</div> */}
                <input className="inputAmount" placeholder="0"></input>
                <div className="" style={{ display: "flex" }}>
                  <div className="totalimgmaxnameAmountBox">
                    <div>
                      <div
                        className="kanch-btn-box-inAmount"
                        style={{ marginBottom: "11px" }}
                      >
                        <div>
                          <img
                            className="amount-img"
                            style={{
                              height: "24px",
                              width: "24px",
                              marginTop: "3px",
                            }}
                            alt=""
                            src={`${process.env.PUBLIC_URL}  /Image/kaanch1.png`}
                          />
                        </div>
                        <div
                          style={{
                            marginRight: "15px",
                            marginTop: "7px",
                            fontSize: "12px",
                          }}
                        >
                          KNCH
                        </div>
                      </div>
                    </div>
                    <div className="max-btn-box">max</div>
                  </div>
                </div>
               
              </div>
              <div className="wallet-balance">Limit balance:1560</div>
              <div style={{ color: "white", marginTop: "30px" }}>
                Transaction Details :
              </div>
              <div className="transanction-box">
                <div className="transanction-box-content1">
                  <div>Supply APY</div>
                  <div>
                    <span className="spanColorAppy">1.78%</span>
                  </div>
                </div>
                <div className="transanction-box-content1">
                  <div>Collateralization</div>
                  <div>
                    <span className="spanColorenable">Enable</span>
                  </div>
                </div>
                <div className="transanction-box-content1">
                  <div>Supply APY</div>
                  <div>
                    <span className="spanColorHealthFact">1.68</span>
                  </div>
                </div>
              </div>
              <div className="transfer-btn">Enter your Amount</div>
             
            </div>
          </div>
        )}
        {/* end of  the popup of borrow */}

        <div className="dashBoardConnected">
          {windowWidth < 1200 ? (
            <>
              {window.innerWidth < 600 ? (
                <>
                  {togleSupp ? (
                    <div className="TitleDashboard">
                      <div className="dashTitle1">
                        <div className="title">Your Supplies</div>
                        <div
                          style={{
                            fontSize: "12px",
                            marginTop: "4rem",
                            color: "#8b8686",
                          }}
                        >
                          Nothing yet to Supply:
                        </div>{" "}
                      </div>
                      {/* ------------------ */}
                      <div
                        className={`dashboardTitleContent ${
                          hidecontent ? "fit-content" : "fixed-height"
                        }`}
                      >
                        <div className="contentTitle">
                          <div className="title">Asset to Supply</div>
                          <div
                            className="title"
                            style={{ cursor: "pointer" }}
                            onClick={hideHandler}
                          >
                            {hidecontent ? (
                              <div>Hide -</div>
                            ) : (
                              <div>Show +</div>
                            )}
                          </div>
                        </div>
                        {hidecontent && (
                          <span>
                            <div className="contentEmptyAlert-btn">
                              Your Kaanch wallet is empty. Purchase or Transfer
                            </div>
                            <div>
                              {arr.map((i) => (
                                <>
                                

                                  <div className="Table-content">
                                    <div className="table-content-asset">
                                      <div >
                                        <img
                                          className="table-content-asset-img" style={{width:'24px',height:'26px'}} alt=""
                                          src="https://w7.pngwing.com/pngs/368/176/png-transparent-ethereum-cryptocurrency-blockchain-bitcoin-logo-bitcoin-angle-triangle-logo-thumbnail.png"
                                        />
                                      </div>
                                      <div className="name" style={{fontSize:'20px', textAlign:'center'}}>Ethreum</div>
                                    </div>

                                    <div style={{ display: "flex",justifyContent:'space-between',marginTop:'5px'}}>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexFlow: "column",
                                      }}
                                    >
                                      <div className="name">
                                        Wallet Balance
                                      </div>
                                      <span className="name">SPY</span>
                                      <span className="name">
                                        Can Be Collateral
                                      </span>
                                    </div>

                                    <div>
                                      {" "}
                                      <div className="name">0</div>
                                      <div className="name">1.66%</div>
                                      <div className="name">
                                        <MdOutlineDone />
                                      </div>
                                    </div>
                                  </div>

                                  
                                      <div
                                        className="table-content-suppl-btn1"
                                        style={{ cursor: "pointer",marginTop:'8px',display:'flex',justifyContent:'center'}}
                                        onClick={supply}
                                      >
                                        Supply
                                      </div>
                                   
                                  </div>
                                </>
                              ))}
                            </div>
                          </span>
                        )}
                      </div>
                      {/* ---------------- */}
                    </div>
                  ) : (
                    <div className="TitleDashboard">
                      <div className="dashTitle1">
                        <div className="title">Your Borrow</div>
                        <div
                          style={{
                            fontSize: "12px",
                            marginTop: "4rem",
                            color: "#8b8686",
                          }}
                        >
                          Nothing yet to Borrow:
                        </div>{" "}
                      </div>
                      {/* ------------------ */}
                      <div
                        className={`dashboardTitleContent ${
                          hidecontenttwo ? "fit-content" : "fixed-height"
                        }`}
                      >
                        <div className="contentTitle">
                          <div className="title">Asset to Borrow</div>
                          <div
                            className="title"
                            style={{ cursor: "pointer" }}
                            onClick={hideHandlertwo}
                          >
                            {hidecontenttwo ? (
                              <div>Hide -</div>
                            ) : (
                              <div>Show +</div>
                            )}
                          </div>
                        </div>
                        {hidecontenttwo && (
                          <span>
                            <div className="contentEmptyAlert-btn">
                              Your Kaanch wallet is empty. Purchase or Transfer
                            </div>
                            <div>
                              {arr.map((i) => (
                                <>
                                

                                  <div className="Table-content">
                                    <div className="table-content-asset">
                                      <div >
                                        <img
                                          className="table-content-asset-img" style={{width:'24px',height:'26px'}} alt=""
                                          src="https://w7.pngwing.com/pngs/368/176/png-transparent-ethereum-cryptocurrency-blockchain-bitcoin-logo-bitcoin-angle-triangle-logo-thumbnail.png"
                                        />
                                      </div>
                                      <div className="name" style={{fontSize:'20px', textAlign:'center'}}>Ethreum</div>
                                    </div>

                                    <div style={{ display: "flex",justifyContent:'space-between',marginTop:'5px'}}>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexFlow: "column",
                                      }}
                                    >
                                      <div className="name">
                                        Wallet Balance
                                      </div>
                                      <span className="name">SPY</span>
                                      <span className="name">
                                        Can Be Collateral
                                      </span>
                                    </div>

                                    <div>
                                      {" "}
                                      <div className="name">0</div>
                                      <div className="name">1.66%</div>
                                      <div className="name">
                                        <MdOutlineDone />
                                      </div>
                                    </div>
                                  </div>

                                  
                                      <div
                                        className="table-content-suppl-btn1"
                                        style={{ cursor: "pointer",marginTop:'8px',display:'flex',justifyContent:'center'}}
                                        onClick={borrow}
                                      >
                                        Borrow
                                      </div>
                                   
                                  </div>
                                </>
                              ))}
                            </div>
                          </span>
                        )}
                      </div>
                      {/* ---------------- */}
                    </div>
                  )}
                </>
              ) : (
                <>
                  {togleSupp ? (
                    <div className="TitleDashboard">
                      <div className="dashTitle1">
                        <div className="title">Your Supplies</div>
                        <div
                          style={{
                            fontSize: "12px",
                            marginTop: "4rem",
                            color: "#8b8686",
                          }}
                        >
                          Nothing yet to Supply:
                        </div>{" "}
                      </div>
                      {/* ------------------ */}
                      <div
                        className={`dashboardTitleContent ${
                          hidecontenttwo ? "fit-content" : "fixed-height"
                        }`}
                      >
                        <div className="contentTitle">
                          <div className="title">Asset to Supply</div>
                          <div
                            className="title"
                            style={{ cursor: "pointer" }}
                            onClick={hideHandlertwo}
                          >
                            {hidecontenttwo ? (
                              <div>Hide -</div>
                            ) : (
                              <div>Show +</div>
                            )}
                          </div>
                        </div>
                        {hidecontenttwo && (
                          <span>
                            <div className="contentEmptyAlert-btn">
                              Your Kaanch wallet is empty. Purchase or Transfer
                            </div>
                            <div className="TableHeading">
                              <div>
                                <span className="textheading">Assets</span>
                                <span className="arrowBtn">
                                  <RiExpandUpDownLine />
                                </span>
                              </div>
                              <div>
                                <span className="textheading">
                                  Wallet Balance
                                </span>
                                <span className="arrowBtn">
                                  <RiExpandUpDownLine />
                                </span>
                              </div>
                              <div>
                                <span className="textheading">SPY</span>
                                <span className="arrowBtn">
                                  <RiExpandUpDownLine />
                                </span>
                              </div>
                              <div>
                                <span className="textheading">
                                  Can Be Collateral
                                </span>
                                <span className="arrowBtn">
                                  <RiExpandUpDownLine />
                                </span>
                              </div>
                              <div></div>
                              <div></div>
                            </div>

                            <>
                              {arr.map((i) => (
                                <>
                                  <div className="Table-content" style={{alignItems:'center'}}>
                                    <div className="table-content-asset">
                                      <div>
                                        <img
                                          className="table-content-asset-img" alt=""
                                          src="https://w7.pngwing.com/pngs/368/176/png-transparent-ethereum-cryptocurrency-blockchain-bitcoin-logo-bitcoin-angle-triangle-logo-thumbnail.png"
                                        />
                                      </div>
                                      <div className="name">Ethreum</div>
                                    </div>
                                    <div className="name">0</div>
                                    <div className="name">1.66%</div>
                                    <div className="name">
                                      <MdOutlineDone />
                                    </div>
                                    <div className="table-content-suppl-btn">
                                      <div
                                        className="table-content-suppl-btn1"
                                        style={{ cursor: "pointer" }}
                                        onClick={supply}
                                      >
                                        Supply
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ))}
                            </>
                          </span>
                        )}
                      </div>
                      {/* ---------------- */}
                    </div>
                  ) : (
                    <div className="TitleDashboard">
                      <div className="dashTitle1">
                        <div className="title">Your Borrow</div>
                        <div
                          style={{
                            fontSize: "12px",
                            marginTop: "4rem",
                            color: "#8b8686",
                          }}
                        >
                          Nothing yet to Borrow:
                        </div>{" "}
                      </div>
                      {/* ------------------ */}
                      <div
                        className={`dashboardTitleContent ${
                          hidecontent ? "fit-content" : "fixed-height"
                        }`}
                      >
                        <div className="contentTitle">
                          <div className="title">Asset to Borrow</div>
                          <div
                            className="title"
                            style={{ cursor: "pointer" }}
                            onClick={hideHandler}
                          >
                            {hidecontent ? (
                              <div>Hide -</div>
                            ) : (
                              <div>Show +</div>
                            )}
                          </div>
                        </div>
                        {hidecontent && (
                          <span>
                            <div className="contentEmptyAlert-btn">
                              Your Kaanch wallet is empty. Purchase or Transfer
                            </div>
                            <div className="TableHeading">
                              <div>
                                <span className="textheading">Assets</span>
                                <span className="arrowBtn">
                                  <RiExpandUpDownLine />
                                </span>
                              </div>
                              <div>
                                <span className="textheading">
                                  Wallet Balance
                                </span>
                                <span className="arrowBtn">
                                  <RiExpandUpDownLine />
                                </span>
                              </div>
                              <div>
                                <span className="textheading">SPY</span>
                                <span className="arrowBtn">
                                  <RiExpandUpDownLine />
                                </span>
                              </div>
                              <div>
                                <span className="textheading">
                                  Can Be Collateral
                                </span>
                                <span className="arrowBtn">
                                  <RiExpandUpDownLine />
                                </span>
                              </div>
                              <div></div>
                              <div></div>
                            </div>

                            <>
                              {arr.map((i) => (
                                <>
                                  <div className="Table-content" style={{alignItems:'center'}}>
                                    <div className="table-content-asset">
                                      <div>
                                        <img
                                          className="table-content-asset-img" alt=""
                                          src="https://w7.pngwing.com/pngs/368/176/png-transparent-ethereum-cryptocurrency-blockchain-bitcoin-logo-bitcoin-angle-triangle-logo-thumbnail.png"
                                        />
                                      </div>
                                      <div className="name">Ethreum</div>
                                    </div>
                                    <div className="name">0</div>
                                    <div className="name">1.66%</div>
                                    <div className="name">
                                      <MdOutlineDone />
                                    </div>
                                    <div className="table-content-suppl-btn">
                                      <div
                                        className="table-content-suppl-btn1"
                                        style={{ cursor: "pointer" }}
                                        onClick={borrow}
                                      >
                                        Borrow
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ))}
                            </>

                            {/*Step2 */}
                          </span>
                        )}
                      </div>
                      {/* ---------------- */}
                    </div>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <div className="TitleDashboard">
                <div className="dashTitle1">
                  <div className="title">Your Supplies</div>
                  <div
                    style={{
                      fontSize: "12px",
                      marginTop: "4rem",
                      color: "#8b8686",
                    }}
                  >
                    Nothing yet to Supply:
                  </div>{" "}
                </div>
                {/* ------------------ */}
                <div
                  className={`dashboardTitleContent ${
                    hidecontent ? "fit-content" : "fixed-height"
                  }`}
                >
                  <div className="contentTitle">
                    <div className="title">Asset to Supply</div>
                    <div
                      className="title"
                      style={{ cursor: "pointer" }}
                      onClick={hideHandler}
                    >
                      {hidecontent ? <div>Hide -</div> : <div>Show +</div>}
                    </div>
                  </div>
                  {hidecontent && (
                    <span>
                      <div className="contentEmptyAlert-btn">
                        Your Kaanch wallet is empty. Purchase or Transfer
                      </div>
                      <div className="TableHeading">
                        <div>
                          <span className="textheading">Assets</span>
                          <span className="arrowBtn">
                            <RiExpandUpDownLine />
                          </span>
                        </div>
                        <div>
                          <span className="textheading">Wallet Balance</span>
                          <span className="arrowBtn">
                            <RiExpandUpDownLine />
                          </span>
                        </div>
                        <div>
                          <span className="textheading">SPY</span>
                          <span className="arrowBtn">
                            <RiExpandUpDownLine />
                          </span>
                        </div>
                        <div>
                          <span className="textheading">Can Be Collateral</span>
                          <span className="arrowBtn">
                            <RiExpandUpDownLine />
                          </span>
                        </div>
                        <div></div>
                        <div></div>
                      </div>
                      <>
                        {arr.map((i) => (
                          <>
                            <div className="Table-content" style={{alignItems:'center'}}>
                              <div className="table-content-asset">
                                <div>
                                  <img
                                    className="table-content-asset-img" alt=""
                                    src="https://w7.pngwing.com/pngs/368/176/png-transparent-ethereum-cryptocurrency-blockchain-bitcoin-logo-bitcoin-angle-triangle-logo-thumbnail.png"
                                  />
                                </div>
                                <div className="name">Ethreum</div>
                              </div>
                              <div className="name">0</div>
                              <div className="name">1.66%</div>
                              <div className="name">
                                <MdOutlineDone />
                              </div>
                              <div className="table-content-suppl-btn">
                                <div
                                  className="table-content-suppl-btn1"
                                  style={{ cursor: "pointer" }}
                                  onClick={supply}
                                >
                                  Supply
                                </div>
                              </div>
                            </div>
                          </>
                        ))}
                      </>
                    </span>
                  )}
                </div>
                {/* ---------------- */}
              </div>
              <div className="TitleDashboard">
                <div className="dashTitle1">
                  <div className="title">Your Borrow</div>
                  <div
                    style={{
                      fontSize: "12px",
                      marginTop: "4rem",
                      color: "#8b8686",
                    }}
                  >
                    Nothing yet to Borrow:
                  </div>{" "}
                </div>
                {/* ------------------ */}
                <div
                  className={`dashboardTitleContent ${
                    hidecontenttwo ? "fit-content" : "fixed-height"
                  }`}
                >
                  <div className="contentTitle">
                    <div className="title">Asset to Borrow</div>
                    <div
                      className="title"
                      style={{ cursor: "pointer" }}
                      onClick={hideHandlertwo}
                    >
                      {hidecontenttwo ? <div>Hide -</div> : <div>Show +</div>}
                    </div>
                  </div>
                  {hidecontenttwo && (
                    <span>
                      <div className="contentEmptyAlert-btn">
                        Your Kaanch wallet is empty. Purchase or Transfer
                      </div>
                      <div className="TableHeading">
                        <div>
                          <span className="textheading">Assets</span>
                          <span className="arrowBtn">
                            <RiExpandUpDownLine />
                          </span>
                        </div>
                        <div>
                          <span className="textheading">Wallet Balance</span>
                          <span className="arrowBtn">
                            <RiExpandUpDownLine />
                          </span>
                        </div>
                        <div>
                          <span className="textheading">SPY</span>
                          <span className="arrowBtn">
                            <RiExpandUpDownLine />
                          </span>
                        </div>
                        <div>
                          <span className="textheading">Can Be Collateral</span>
                          <span className="arrowBtn">
                            <RiExpandUpDownLine />
                          </span>
                        </div>
                        <div></div>
                        <div></div>
                      </div>
                      <>
                        {arr.map((i) => (
                          <>
                            <div className="Table-content" style={{alignItems:'center'}}>
                              <div className="table-content-asset">
                                <div>
                                  <img
                                    className="table-content-asset-img" alt=""
                                    src="https://w7.pngwing.com/pngs/368/176/png-transparent-ethereum-cryptocurrency-blockchain-bitcoin-logo-bitcoin-angle-triangle-logo-thumbnail.png"
                                  />
                                </div>
                                <div className="name">Ethreum</div>
                              </div>
                              <div className="name">0</div>
                              <div className="name">1.66%</div>
                              <div className="name">
                                <MdOutlineDone />
                              </div>
                              <div className="table-content-suppl-btn">
                                <div
                                  className="table-content-suppl-btn1"
                                  style={{ cursor: "pointer" }}
                                  onClick={borrow}
                                >
                                  Borrow
                                </div>
                              </div>
                            </div>
                          </>
                        ))}
                      </>
                    </span>
                  )}
                </div>
                {/* ---------------- */}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
