import "./Stake.css";
import React, { useContext, useEffect, useState } from "react";
import { RiArrowDownSLine } from "react-icons/ri";
import { MdOutlineSwapVerticalCircle } from "react-icons/md";
import { ThemeContext } from "../ThemeContext";
const Stake = () => {
  const { isDarkMode } = useContext(ThemeContext);
  const [stakeUnstake, setstakeUnstake] = useState(1);
  const [claimState, setclaimState] = useState(false);

  const [activeButton, setActiveButton] = useState("stake");

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };
  const swapStakeUnstake = () => {
    if (activeButton === "stake") {
      setActiveButton("unstake");
    } else if (activeButton === "unstake") {
      setActiveButton("stake");
    }
  };
  const StakeFun = () => {
    setstakeUnstake(1);
  };

  const UnstakeFun = () => {
    setstakeUnstake(0);
  };
  const ClaimFun = () => {
    setclaimState(true);
  };
  return (
    <div className={isDarkMode ? "dark-mode-homepage" : "light-mode-homepage"}>
      <div className="home">
        <div className="headerTopBg"></div>
        <div className="stake-container">
          <div className="firstDivHeading">
            <div className="firstDivHeadingLeftSide">
              <div
                className={`firstDivHeadingLeftSide-box ${
                  activeButton === "stake" ? "active" : ""
                }`}
                onClick={() => handleButtonClick("stake")}
              >
                Stake
              </div>
              <div
                className={`firstDivHeadingLeftSide-box ${
                  activeButton === "unstake" ? "active" : ""
                }`}
                onClick={() => handleButtonClick("unstake")}
              >
                Unstake
              </div>
              <div
                className={`firstDivHeadingLeftSide-box ${
                  activeButton === "claim" ? "active" : ""
                }`}
                onClick={() => handleButtonClick("claim")}
              >
                Claim
              </div>
            </div>
            <div className="firstDivHeadingRightSide">USD APY 66.66%</div>
          </div>

          {activeButton === "stake" && (
            <>
              <div className="secDivStake">
                <div className="secDivStakeFirstColumn">
                  Your {activeButton}
                </div>
                <div className="secDivStakeSecColumn">
                  <input placeholder="0" className="inputsecColStake"></input>
                  <div className="secDivStakeSecColumn-selectdrop">
                    <img
                      className="imgLogo"
                      alt=""
                      src={`${process.env.PUBLIC_URL}  /Image/kaanch1.png`}
                    />
                    Kaanch{""}
                    <span>{/* <RiArrowDownSLine /> */}</span>
                  </div>
                </div>
                <div className="secDivStakeSecColumn-max-balance">
                  <button className="max-btn">Max</button> Balance: 0
                </div>
              </div>
              <div className="swapp-btn-section">
                {/* <hr className="swapp-btn-left" /> */}
                <span></span>
                <span className="swapp-btn" onClick={swapStakeUnstake}>
                  <MdOutlineSwapVerticalCircle />
                </span>
                {/* <hr className="swapp-btn-right" /> */}
                <span></span>
              </div>
              <div className="secDivStake">
                <div className="secDivStakeFirstColumn">You Recieve</div>
                <div className="secDivStakeSecColumn">
                  <input placeholder="0" className="inputsecColStake"></input>
                  <div className="secDivStakeSecColumn-selectdrop">
                    <img
                      className="imgLogo"
                      src="https://seeklogo.com/images/P/polygon-matic-logo-1DFDA3A3A8-seeklogo.com.png"
                    />
                    Polygon
                    <span>{/* <RiArrowDownSLine /> */}</span>
                  </div>{" "}
                </div>
                <div className="secDivStakeSecColumn-max-balance">
                  {" "}
                  Balance: 0
                </div>
              </div>
              <div className="fifthDiv">
                <div>Stat Eligible</div>
                <div>Gas Price $6.56</div>
              </div>
              <div className="sixthDiv">
                Kaanch will be available to Claim 10 days after Unstaking
              </div>
              <div className="seventhDiv">{activeButton}</div>
            </>
          )}



          {/*Unstake section start */}

          {activeButton === "unstake" && (
            <>
              <div className="secDivStake">
                <div className="secDivStakeFirstColumn">
                  Your {activeButton}
                </div>
                
                

                <div className="secDivStakeSecColumn">
                  <input placeholder="0" className="inputsecColStake"></input>
                  <div className="secDivStakeSecColumn-selectdrop">
                    <img
                      className="imgLogo"
                      src="https://seeklogo.com/images/P/polygon-matic-logo-1DFDA3A3A8-seeklogo.com.png"
                    />
                    Polygon
                    <span>{/* <RiArrowDownSLine /> */}</span>
                  </div>
                </div>
                <div className="secDivStakeSecColumn-max-balance">
                  <button className="max-btn">Max</button> Balance: 0
                </div>
              </div>
              <div className="swapp-btn-section">
                {/* <hr className="swapp-btn-left" /> */}
                <span></span>
                <span className="swapp-btn" onClick={swapStakeUnstake}>
                  <MdOutlineSwapVerticalCircle />
                </span>
                {/* <hr className="swapp-btn-right" /> */}
                <span></span>
              </div>
              <div className="secDivStake">
                <div className="secDivStakeFirstColumn">You Recieve</div>
                <div className="secDivStakeSecColumn">
                  <input placeholder="0" className="inputsecColStake"></input>
                  <div className="secDivStakeSecColumn-selectdrop">
                    <img
                      className="imgLogo"
                      alt=""
                      src={`${process.env.PUBLIC_URL}  /Image/kaanch1.png`}
                    />
                    Kaanch{""}
                    <span>{/* <RiArrowDownSLine /> */}</span>
                  </div>
                </div>
                <div className="secDivStakeSecColumn-max-balance">
                  {" "}
                  Balance: 0
                </div>
              </div>
              <div className="fifthDiv">
                <div>Stat Eligible</div>
                <div>Gas Price $6.56</div>
              </div>
              <div className="sixthDiv">
                Kaanch will be available to Claim 10 days after Unstaking
              </div>
              <div className="seventhDiv">{activeButton}</div>
            </>
          )}

          {/* unstake end */}



          {/* claim start */}

          {activeButton === "claim" && (
            <>
              <div className="secDivStake">
                <div className="secDivStakeFirstColumn">
                  Your {activeButton}
                </div>
                <div className="secDivStakeSecColumn">
                  <input placeholder="0" className="inputsecColStake"></input>
                  <div className="secDivStakeSecColumn-selectdrop">
                    <img
                      className="imgLogo"
                      alt=""
                      src={`${process.env.PUBLIC_URL}  /Image/kaanch1.png`}
                    />
                    Kaanch{""}
                    <span>{/* <RiArrowDownSLine /> */}</span>
                  </div>
                </div>
                <div className="secDivStakeSecColumn-max-balance">
                  <button className="max-btn">Max</button> Balance: 0
                </div>
              </div>
             
              <div className="fifthDiv">
                <div>Stat Eligible</div>
                <div>Gas Price $6.56</div>
              </div>
              <div className="sixthDiv">
                Kaanch will be available to Claim 10 days after Unstaking
              </div>
              <div className="seventhDiv">{activeButton}</div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Stake;
