import React from 'react';
import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Header from './components/Header';


import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { ThemeProvider } from './ThemeContext';
import {Globaldataprovider} from './ContextProvider';
import Footer from './components/Footer';
import Dashboard from './components/Dashboard';
import Market from './components/Market';
import Stack from './components/Stake';
import Governance from './components/Governance';
import Stake from './components/Stake';


const App = () => {
  return (
    <div  >
      <ThemeProvider>
        <Globaldataprovider>
      <Router>
        <Header />
        <Routes>
          <Route path="/"  element={<Dashboard />}/>
          <Route path="/market"  element={<Market />}/>
          <Route path="/stake"  element={<Stake/>}/>
          <Route path="/gov"  element={<Governance/>}/>
          

          {/* <Route path="/signup"  element={<Protected Component={ReferalPage} />}/> */}
          {/* <Route path="*"  element={<Homepage />}/> */}
       
        </Routes>
        <Footer/>
      </Router>
      </Globaldataprovider>
      </ThemeProvider>
    </div>
  )
}

export default App;
