import React, { createContext, useState } from "react";

const Mycontextprovider = createContext()

export function Globaldataprovider({ children }) {
    const [Isdisconnected,setIsdisconnected] =useState(false)
    const [connectedAddress,setconnectedAddress] = useState()
    const [updateperformTask,setupdateperformTask] = useState(0)
    const [homeORreferral,sethomeORreferral] = useState(false)
    const [backReferral,setbackReferral] = useState(false)

    function Isdisconnected_seted(params) {
        setIsdisconnected(params)
    }

    function IsbackReferral(params) {
        setbackReferral(params)
    }

    
    function Connecting_Owner_Address(params) {
        setconnectedAddress(params)
    }

    function IshomeORreferral(params) {
        sethomeORreferral(params)
    }

    const performTask = (paramsValue) => {
  
        setupdateperformTask(paramsValue);
     
    };

    return (
        <Mycontextprovider.Provider value={{
            Isdisconnected_seted,Isdisconnected,
            backReferral,IsbackReferral,
            Connecting_Owner_Address,connectedAddress,
            IshomeORreferral,homeORreferral,
            performTask,updateperformTask
            
        }}>{children}</Mycontextprovider.Provider>
    )

}
export default Mycontextprovider;



